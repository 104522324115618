import { applicationConfig } from "../application-configuration";
import { applicationSettingsStore } from "../store";
import { useFeatureFlagStore } from "../store/feature-flags";
import { ApiConfiguration } from "../types";
const prodHostNames = ["apps.mastercontrol.com", "test.apps.mastercontrol.com"];

export const apiConfig: ApiConfiguration = {
    get accessToken() {
        return applicationSettingsStore.getState().accessToken;
    },
    get basePath() {
        const basePath = applicationConfig?.api?.rest?.urls?.default ?? "";
        if (!basePath) {
            // NOTE: We can't provide a valid configuration without
            // a base API path.
            throw new Error("No API base path is configured.");
        }
        return basePath;
    },
    get headers() {
        const globalOverrides = useFeatureFlagStore.getState().overrides;
        let requestOverrides: string | null = null;
        try {
            if (Object.keys(globalOverrides).length) {
                const localFlags = localStorage.getItem("featureFlags");
                if (localFlags) {
                    requestOverrides = JSON.stringify(Object.assign(JSON.parse(localFlags), globalOverrides));
                } else {
                    requestOverrides = JSON.stringify(globalOverrides);
                }
            } else {
                requestOverrides = localStorage.getItem("featureFlags");
            }
        } catch (e) {
            console.error("error loading X-Mc-Flag-Overrides", e);
        }

        const result: Record<string, string> = {
            "Mc-Application-Id": window.location.pathname.split("/")[1],
        };
        const isProd = prodHostNames.includes(window.location.hostname);
        if (!isProd && requestOverrides) {
            result["X-Mc-Flag-Overrides"] = requestOverrides;
        }
        return result;
    },
};
