import { createStore } from "zustand/vanilla";
import { AuthenticationSlice, createAuthenticationSlice } from "./authentication-slice";
import { createDateTimeSlice, DateTimeSlice } from "./date-time-slice";
import { createSearchSessionIdSlice, SearchSessionIdSlice } from "./search-session-id-slice";

/**
 * Provides access to application setting values.
 * Outside of a React component, you can consume it by using `applicationSettingsStore.getState()` to get a current snapshot or you can use `applicationSettingsStore.subscribe(s => s.someStateValue)`to subscribe to changes in a value.
 * Inside of a React component, you can consume it by using `useStore(applicationSettingsStore)`.
 *
 * Don't add new slices to this unless they are absolutely necessary. These values create couplings between applications; if there are too many couplings, refactoring becomes difficult.
 */
export const applicationSettingsStore = createStore<AuthenticationSlice & SearchSessionIdSlice & DateTimeSlice>(
    (...args) => ({
        ...createDateTimeSlice(...args),
        ...createSearchSessionIdSlice(...args),
        ...createAuthenticationSlice(...args),
    }),
);
