import { StateCreator } from "zustand";

type SearchSessionIdSliceState = {
    /**
     * The Search Session ID for the current series of searches, if one is present.
     *
     * If a search request is made and an existing `ssid` is not provided, the search
     * response will contain one that should be stored here. Subsequent search requests
     * should include the stored `ssid`.
     *
     * The `ssid` should be cleared when the search session has reached its completion,
     * most commonly when a user clicks on a result.
     */
    ssid: string | null;
};

type SearchSessionIdSliceActions = {
    /** Set the Search Session ID. */
    setSsid: (ssid: string | null) => void;
};

const SESSION_STORAGE_KEY = "search-session-id";

export type SearchSessionIdSlice = SearchSessionIdSliceState & SearchSessionIdSliceActions;

export const createSearchSessionIdSlice: StateCreator<SearchSessionIdSlice> = (set) => {
    // Since application-settings is not built exclusively for browsers, we can't depend on
    // `window.sessionStorage` being available. So this is to keep that from breaking things,
    // primarily in our pipeline.
    const sessionStorage = globalThis.sessionStorage ?? {
        getItem: () => null,
        removeItem: () => {},
        setItem: () => {},
    };
    return {
        setSsid: (ssid) => {
            // We store the SSID in sessionStorage so that it persists through page loads and
            // refreshes within the same tab and user session.
            if (ssid) {
                sessionStorage.setItem(SESSION_STORAGE_KEY, ssid);
            } else {
                sessionStorage.removeItem(SESSION_STORAGE_KEY);
            }
            set({ ssid });
        },
        ssid: sessionStorage.getItem(SESSION_STORAGE_KEY),
    };
};
