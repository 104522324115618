/* eslint-disable sort-keys */
import {
    createReactRouterV6DataOptions,
    getWebInstrumentations,
    initializeFaro,
    ReactIntegration,
} from "@grafana/faro-react";
import { TracingInstrumentation } from "@grafana/faro-web-tracing";
import { matchRoutes } from "react-router-dom";

type GrafanaConfig = {
    environment: string;
    url: string;
};

type SingleSPAAppChangeEvent = CustomEvent<{
    appsByNewStatus: Record<string, string[]>;
    newAppStatuses: Record<string, string>;
    originalEvent: PopStateEvent;
    totalAppChanges: number;
}>;

let APP_NAME: undefined | string = undefined;
window.addEventListener("single-spa:app-change", (event?: unknown) => {
    if (!event) {
        return;
    }

    const mountedApps = (event as SingleSPAAppChangeEvent)?.detail?.appsByNewStatus?.MOUNTED ?? [];

    // When hard refreshing the page, the navigation app is also mounted, so it's filtered out to get the actual app name.
    const appName =
        mountedApps.length === 2 ? mountedApps.find((app: string) => app !== "@mc/navigation-web") : mountedApps[0];

    APP_NAME = appName;
});

export const setupGrafana = (config: GrafanaConfig) => {
    initializeFaro({
        beforeSend: (event) => {
            // Ensure that the app name is set for all events.
            return {
                ...event,
                meta: {
                    ...event.meta,
                    app: {
                        environment: config.environment,
                        name: APP_NAME,
                    },
                },
            };
        },
        app: {
            environment: config.environment,
            name: APP_NAME,
        },
        batching: {
            enabled: true,
            itemLimit: 50,
            sendTimeout: 5000,
        },
        instrumentations: [
            // Mandatory, omits default instrumentations otherwise.
            ...getWebInstrumentations({
                enablePerformanceInstrumentation: true,
            }),

            // Tracing package to get end-to-end visibility for HTTP requests.
            new TracingInstrumentation(),

            // React integration for React applications.
            new ReactIntegration({
                router: createReactRouterV6DataOptions({
                    matchRoutes,
                }),
            }),
        ],

        url: config.url,
        trackWebVitalsAttribution: true,
    });
};
