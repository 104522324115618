import { ApplicationConfiguration } from "../application-configuration";
import { setupGrafana } from "./grafana";

declare global {
    interface Window {
        dataLayer: Record<string, unknown>[];
    }
}

/**
 * Sets up the client-side observability provider, which is currently DataDog RUM.
 */
export const setupObservabilityProvider = (applicationConfig: ApplicationConfiguration) => {
    if (applicationConfig.grafana) {
        setupGrafana(applicationConfig.grafana);
    }
};
