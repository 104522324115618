declare global {
    interface Window {
        mcApplicationSettings: typeof import("@mc/application-settings");
        singleSpa: typeof import("single-spa");
    }
}

/**
 * Loads local packages.
 * @note This should happen after remote packages are loaded, as local packages may depend on remote packages.
 */
async function loadLocalPackages() {
    // First dependencies to load
    await Promise.all([import("single-spa").then((module) => (window.singleSpa = module))]);

    // Second group, which depends on the first group
    window.mcApplicationSettings = await import("@mc/application-settings");
}

/**
 * Loads all dependencies for the application to bootstrap.
 */
export async function loadDependencies() {
    await loadLocalPackages();
}
