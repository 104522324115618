import { JwtPayload, jwtDecode } from "jwt-decode";

export const isAuthFlow = () => {
    const location = window.location;
    const searchParams = new URLSearchParams(location.search);

    const qxTenant = searchParams.get("qxTenant");
    const oktaToken = searchParams.get("oktaToken");

    const jwt = searchParams.get("token");
    const dest = searchParams.get("dest");

    const isOldFlow = !!qxTenant && !!oktaToken;
    const isNewJwtFlow = !!jwt && !!dest;

    return isOldFlow || isNewJwtFlow;
};

export const getTtlFromClaims = (claims: JwtPayload | null) => {
    return claims && claims.exp ? claims.exp - Math.floor(Date.now() / 1000) : undefined;
};

export const getLifetimeFromClaims = (claims: JwtPayload | null) => {
    return claims && claims.exp && claims.iat ? claims.exp - claims.iat : undefined;
};

export const getIdleTimeFromClaims = (claims: (JwtPayload & { idleTime?: number }) | null) => {
    return (claims && claims?.idleTime) || undefined;
};

export const getTtlFromToken = (accessToken: string) => {
    const claims = jwtDecode(accessToken);
    return getTtlFromClaims(claims);
};

export const getIdleTimeFromToken = (accessToken: string) => {
    const claims = jwtDecode(accessToken);
    return getIdleTimeFromClaims(claims);
};

export const getTokenLifetimeFromToken = (accessToken: string) => {
    const claims = jwtDecode(accessToken);
    return getLifetimeFromClaims(claims);
};

export const fetchSessionToken = async (mcIdpToken: string, apiBaseUrl: string) => {
    const { tenantId } = jwtDecode<{ tenantId: string }>(mcIdpToken);
    const res = await fetch(`${apiBaseUrl}/pcs/session/auth/v1/start`, {
        body: JSON.stringify({
            qxTenantId: tenantId,
            token: mcIdpToken,
            ttl: 60000,
        }),
        headers: {
            "Content-Type": "application/json",
        },
        method: "POST",
    });
    const response = await res.json();
    return response.mcToken as string;
};

export const getMcIdpRealm = () => {
    const REALM_STORAGE_KEY = "mcIdpRealm";
    const REALM_SEARCH_PARAMS_KEY = "realm";
    const storedRealm = localStorage.getItem(REALM_STORAGE_KEY);
    const url = new URL(window.location.href);
    const searchParamsRealm = url.searchParams.get(REALM_SEARCH_PARAMS_KEY);

    if (!searchParamsRealm && !storedRealm) {
        throw new Error("Please specify realm in search params (ex: ?realm=gokhan-san-24)");
    }

    if (searchParamsRealm) {
        localStorage.setItem(REALM_STORAGE_KEY, searchParamsRealm);

        url.searchParams.delete(REALM_SEARCH_PARAMS_KEY);
        window.history.replaceState({}, "", url);

        return searchParamsRealm;
    } else {
        return storedRealm;
    }
};
