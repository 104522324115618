import * as singleSpa from "single-spa";

// NOTE: Grafana already has a built-in error handler
// that will catch
// 1. console.error()
// 3. window.onerror and window.onunhandledrejection
// See https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/

/**
 * Handles errors by logging them to the console and displaying an error message to the user.
 * @param error The error to handle.
 */
export const errorHandler = (error: unknown) => {
    console.error(error);
    const userLanguage = navigator.language.split("-")[0];
    const errorLanguages = ["de", "en", "es", "fr", "ja", "zh"];
    const targetLanguage = errorLanguages.includes(userLanguage) ? userLanguage : "en";
    const rootErrorContainer = document.getElementById(`root-error-container-${targetLanguage}`);
    if (rootErrorContainer) {
        rootErrorContainer.style.display = "grid";
    }
};

singleSpa.addErrorHandler((error: singleSpa.AppError) => {
    // This will be picked up by grafana and logged as an error
    console.error(error, { singleSpaError: true });

    // A catastrophic error occurred while bootstrapping the application.
    // As such, we are in an undefined state and cannot safely recover.
    // Per a discussion with Jared, we'd like to display an error message to the user
    // and force a page reload here.
    // See https://single-spa.js.org/docs/api/#getappstatus
    // NOTE: addErrorHandler is only triggered on catastrophic errors
    // for any given application.
    errorHandler(error);
});

/**
 * Waits for the first application to be mounted by single-spa.
 * @param timeout The maximum time (in milliseconds) to wait for the event to be fired.
 * @returns A promise that resolves when the event is fired.
 */
export function waitForSpaFirstMount(timeout: number): Promise<Event> {
    const eventType = "single-spa:first-mount";
    return new Promise((resolve, reject) => {
        const timeoutId = setTimeout(() => {
            window.removeEventListener(eventType, onEvent);
            reject(new Error(`Timeout: Event "${eventType}" was not fired within ${timeout}ms`));
        }, timeout);

        const onEvent = (event: Event) => {
            clearTimeout(timeoutId);
            window.removeEventListener(eventType, onEvent);
            resolve(event);
        };

        window.addEventListener(eventType, onEvent);
    });
}
