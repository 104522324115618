import { create } from "zustand";
import { produce } from "immer";
import { FeatureFlagsMap } from "../feature-flags";

// reference counter to track global flag usage for active components
const usageCounts: Record<string, number> = {};

export const useFeatureFlagStore = create<{
    flags: { [flagName: string]: boolean };
    overrides: { [flagName: string]: boolean };
    setFlag: (flagName: string, value: boolean) => void;
    trackFlags: (flags: FeatureFlagsMap<string>) => () => void;
}>((set) => ({
    flags: {},
    overrides: {},
    setFlag(flagName, value) {
        set(
            produce((state) => {
                if (state.flags[flagName] !== value) {
                    state.overrides[flagName] = value;
                } else {
                    delete state.overrides[flagName];
                }
            }),
        );
    },
    trackFlags(flags) {
        set(
            produce((state) => {
                for (const flagName in flags) {
                    if (!usageCounts[flagName]) {
                        usageCounts[flagName] = 1;
                        state.flags[flagName] = flags[flagName];
                    } else {
                        usageCounts[flagName]++;
                    }
                }
            }),
        );
        // untrack flags
        return () => {
            set(
                produce((state) => {
                    for (const flagName in flags) {
                        if (usageCounts[flagName]) {
                            usageCounts[flagName]--;
                            if (usageCounts[flagName] <= 0) {
                                delete usageCounts[flagName];
                                delete state.flags[flagName];
                                delete state.overrides[flagName];
                            }
                        }
                    }
                }),
            );
        };
    },
}));
