import { buildUriRegexCollection } from "./uri";

export const RemoteAppUris = {
    adocs: "/remote-assets/adocs/index.js",
    amx: "/remote-assets/amx/index.js",
    amxConfig: "/remote-assets/amx-config/index.js",
    aqem: "/remote-assets/aqem/index.js",
    atraining: "/remote-assets/atraining/index.js",
    examBuilder: "/remote-assets/exam-builder/index.js",
    homepage: "/remote-assets/homepage/index.js",
    insights: "/remote-assets/insights/index.js",
    integrations: "/remote-assets/integrations/index.js",
    logbooks: "/remote-assets/logbooks/index.js",
    mcadmin: "/remote-assets/mcadmin/index.js",
    navigation: "/remote-assets/navigation/index.js",
    regulatoryAssistant: "/remote-assets/regulatory-assistant/index.js",
    releaseCenter: "/remote-assets/release-center/index.js",
    summarizer: "/remote-assets/summarizer/index.js",
    translator: "/remote-assets/translator/index.js",
};

export const RemoteAppRegexUris = buildUriRegexCollection(RemoteAppUris);
