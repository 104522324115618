import { UserManager, WebStorageStateStore } from "oidc-client-ts";
import OktaAuth, { toRelativeUrl } from "@okta/okta-auth-js";
import { ApplicationConfiguration } from "../types";
import { fetchSessionToken, getMcIdpRealm } from "./utils";

type AuthClientOptions = {
    applicationConfiguration: ApplicationConfiguration;
    isMcIdpAuth?: boolean;
};

export type AuthClientSubscribeHandlerOptions = {
    accessToken?: string | null;
    isAuthenticated?: boolean;
};

type AuthClientSubscribeHandler = (options: AuthClientSubscribeHandlerOptions) => void;

export class AuthClient {
    private mcIdpClient: UserManager | null = null;
    private oktaClient: OktaAuth | null = null;
    private listeners: Set<AuthClientSubscribeHandler> = new Set();
    applicationConfiguration: ApplicationConfiguration = {};
    sessionToken: string | null = null;
    mcIdpToken: string | null = null;
    isMcIdpAuth = false;

    constructor({ applicationConfiguration, isMcIdpAuth }: AuthClientOptions) {
        this.applicationConfiguration = applicationConfiguration;
        this.isMcIdpAuth = !!isMcIdpAuth;
    }

    async _initMcIdpClient() {
        const mcIdpRealm = getMcIdpRealm();
        this.mcIdpClient = new UserManager({
            authority: `${this.applicationConfiguration.authentication.mcIdpAuthority}/realms/${mcIdpRealm}`,
            client_id: "tenant-login-page",
            monitorSession: true,
            post_logout_redirect_uri: window.location.origin,
            redirect_uri: this.getRedirectUrl(this.applicationConfiguration.authentication.redirectUri),
            userStore: new WebStorageStateStore({ store: window.localStorage }),
        });

        this.mcIdpClient.events.addUserLoaded(() => this.notifySubscribers());
        this.mcIdpClient.events.addUserUnloaded(() => this.notifySubscribers());

        await this.notifySubscribers();
    }

    _initOktaClient(shouldBeMocked?: boolean) {
        const oktaConfig = shouldBeMocked
            ? {
                  issuer: "https://mclabstest.oktapreview.com/oauth2/default",
              }
            : {
                  ...this.applicationConfiguration.authentication,
                  redirectUrl: this.getRedirectUrl(this.applicationConfiguration.authentication.redirectUri),
                  restoreOriginalUri: (_oktaAuth: OktaAuth, originalUri: string) => {
                      const uri = toRelativeUrl(originalUri || "/", window.location.origin);
                      window.location.replace(uri);
                  },
              };

        this.oktaClient = new OktaAuth(oktaConfig);
    }

    getRedirectUrl(redirectUri: string) {
        // Use a relative path if a relative path is provided in the config. Use an absolute path if not.
        return globalThis.document.baseURI // This will create an url based on the document's base path if the config url is relative, or it will create an absolute url if the config url is absolute
            ? new URL(redirectUri, globalThis.document.baseURI).toString()
            : redirectUri;
    }

    async getSessionToken(mcIdpToken?: string) {
        if (!mcIdpToken) {
            return null;
        }

        if (mcIdpToken === this.mcIdpToken && !!this.sessionToken) {
            return this.sessionToken;
        }

        try {
            const sessionToken = await fetchSessionToken(
                mcIdpToken,
                this.applicationConfiguration.api.rest.urls.default,
            );

            this.sessionToken = sessionToken;
            this.mcIdpToken = mcIdpToken;
            return sessionToken;
        } catch {
            return mcIdpToken;
        }
    }

    async handleLoginRedirect() {
        if (this.isMcIdpAuth) {
            const user = await this.mcIdpClient?.signinRedirectCallback();
            await this.notifySubscribers();

            if (user?.state) {
                window.location.replace((user.state as unknown as { originalUri: string }).originalUri);
            }
            return;
        }

        await this.oktaClient?.handleLoginRedirect();
    }

    isLoginRedirect() {
        if (this.isMcIdpAuth) {
            return window.location.search.includes("code=") || window.location.search.includes("error=");
        }

        return !!this.oktaClient?.isLoginRedirect();
    }

    private async notifySubscribers() {
        const user = await this.mcIdpClient?.getUser().catch(() => null);
        const sessionToken = await this.getSessionToken(user?.access_token);
        this.listeners.forEach((callback) =>
            callback({
                accessToken: sessionToken,
                isAuthenticated: Boolean(user && !user.expired),
            }),
        );
    }

    async signInWithRedirect({ originalUri, prompt }: { originalUri: string; prompt: string }) {
        if (this.isMcIdpAuth) {
            const user = await this.mcIdpClient?.getUser().catch(() => null);
            if (user && !user.expired) {
                await this.notifySubscribers();
                return;
            }
            await this.mcIdpClient?.signinRedirect({ prompt, state: { originalUri } });
            await this.notifySubscribers();
            return;
        }

        await this.oktaClient?.signInWithRedirect({ originalUri, prompt });
    }

    async signOut(config?: { postLogoutRedirectUri?: string }) {
        if (this.isMcIdpAuth) {
            await this.mcIdpClient?.signoutRedirect({
                post_logout_redirect_uri: config?.postLogoutRedirectUri || window.location.origin,
            });

            this.sessionToken = null;
            this.mcIdpToken = null;

            await this.notifySubscribers();
            return;
        }

        await this.oktaClient?.signOut(config);
    }

    tokenManager = {
        clear: async () => {
            if (this.isMcIdpAuth) {
                await this.mcIdpClient?.removeUser();
                return;
            }
            this.oktaClient?.tokenManager.clear();
        },
    };

    subscribe(callback: AuthClientSubscribeHandler) {
        if (this.isMcIdpAuth) {
            this.listeners.add(callback);
            return;
        }
        this.oktaClient?.authStateManager.subscribe((authState) => {
            callback({
                accessToken: authState.accessToken?.accessToken,
                isAuthenticated: authState.isAuthenticated,
            });
        });
        this.oktaClient?.start();
    }

    unsubscribe() {
        this.listeners.clear();
    }
}
